import cx from 'classnames'
import { useContext, useMemo } from 'react'

import { SanityLinkBlock } from '@data/sanity/queries/types/blocks'
import { SanityVideoAspectRatioValue } from '@data/sanity/queries/types/video'
import { textColorClasses } from '@lib/color'
import { getFormattedDate } from '@lib/format'
import { getImageRatio } from '@lib/image'
import { LanguageContext } from '@lib/language-context'
import { getFormattedQueryString, getPageUrl } from '@lib/routes'
import { StringsContext } from '@lib/strings-context'

import MuxVideo from '@components/video/mux-video'
import Photo from '@components/photo'
import SimpleLink from '@components/simple-link'

type LinkBlockProps = Pick<
  SanityLinkBlock,
  | 'link'
  | 'text'
  | 'textColor'
  | 'backgroundType'
  | 'mobilePhoto'
  | 'photo'
  | 'muxVideo'
> & {
  photoSizes?: string
  className?: string
}

const LinkBlock = ({
  link,
  text,
  textColor,
  backgroundType,
  mobilePhoto,
  photo,
  photoSizes,
  muxVideo,
  className,
}: LinkBlockProps) => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)

  const url = useMemo(() => {
    const pageUrl = getPageUrl(locale, link.type, link.slug?.current)
    const formattedQueryString = getFormattedQueryString(link.queryString)

    return `${pageUrl}${formattedQueryString}`
  }, [])

  const activePhoto = photo
  const activeMobilePhoto = mobilePhoto?.asset ? mobilePhoto : photo

  return (
    <SimpleLink
      href={url}
      className={cx('block h-full relative group', className)}
    >
      <div className="absolute inset-0">
        <span
          className={cx(
            'block sticky left-0 top-[var(--headerHeight)] z-10 p-3',
            'uppercase text-xs leading-tight',
            textColorClasses[textColor],
          )}
        >
          {link.date && (
            <>
              {getFormattedDate(link.date, locale)}
              <br />
            </>
          )}
          {text}{' '}
          <span className="hidden group-hover:inline">
            —{' '}
            {link.type === 'blogPost'
              ? strings.blogPostReadMore
              : strings.buttonShopNow}
          </span>
        </span>
      </div>

      {backgroundType === 'photo' && (
        <>
          {!!activePhoto && (
            <Photo
              image={activePhoto}
              showCaption={false}
              sizes={photoSizes}
              className="hidden sm:block relative h-full sm:p-0"
              style={{
                paddingTop: `${
                  100 / getImageRatio(activePhoto.customRatio, 16 / 9)
                }%`,
              }}
              imageClassName="w-full h-full object-cover absolute inset-0 static"
            />
          )}
          {!!activeMobilePhoto && (
            <Photo
              image={activeMobilePhoto}
              showCaption={false}
              sizes={photoSizes}
              className="sm:hidden relative h-full sm:p-0"
              style={{
                paddingTop: `${
                  100 / getImageRatio(activeMobilePhoto.customRatio, 5 / 7)
                }%`,
              }}
              imageClassName="w-full h-full object-cover absolute inset-0 static"
            />
          )}
        </>
      )}

      {backgroundType === 'mux-video' && !!muxVideo && (
        <MuxVideo
          video={muxVideo}
          showControls={false}
          autoplay
          muted
          loop
          customAspectRatio={{
            custom: true,
            mobile: '1:1',
            desktop: muxVideo.asset.data
              .aspect_ratio as SanityVideoAspectRatioValue,
          }}
        />
      )}
    </SimpleLink>
  )
}

export default LinkBlock
